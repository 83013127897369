// Body
$body-bg                : #fff;
$body-color             : #000;

$grey-light             : #f5f5f5;

// Typography
$font-family-sans-serif : "futura-pt", sans-serif;
$font-weight-base       : 300;
$font-size-base         : 1.25rem;

$primary                : #e51e21;
$secondary              : #4141CC;


$fa-font-path           : "../../../fonts" !default;

$enable-rounded         : false;

$headings-font-weight   : 600;
$h1-font-size           : 35px;
$h2-font-size           : 20px;

$btn-border-radius      : 5px;
$btn-font-weight        : 500;
$btn-font-size          : 20px;
