a {
    transition : color .3s;

    &.btn {
        &:after {
            content : none;
        }
    }
}

h2 {
    text-transform : uppercase;
}

.btn {
    border-radius : 5px;
    padding       : 0 20px;
}

#app {
    min-height     : 100vh;
    padding-bottom : 200px;
    position       : relative;
}

footer {
    position         : absolute;
    bottom           : 0;
    left             : 0;
    right            : 0;
    width            : 100%;
    background-color : $grey-light;
    padding-top      : 30px;
    padding-bottom   : 30px;

    .copyright {
        padding-left : 11px;
        text-align   : left;
        font-size    : 13px;
    }

    ul {
        text-align    : right;
        margin-bottom : 0;
        padding-left  : 0;

        @include media-breakpoint-down(md) {
            text-align : left;
        }

        li {
            display       : inline-block;
            padding-right : 6px;
            margin-left   : 50px;

            &:first-of-type {
                margin-left : 0;
            }

            &:last-child {
                border-right : none;
            }

            a {
                color           : black;
                font-size       : 13px;
                text-decoration : none;

                &:hover {
                    color : $primary;
                }
            }
        }
    }
}

#lang-switch {
    top   : 0;
    right : $grid-gutter-width / 2;

    @include media-breakpoint-down(md) {
        display : none;
    }

    a {
        &.active {
            text-decoration : underline;
        }
    }
}

#navbarSupportedContent {
    align-self : flex-end;

    a {
        margin-left : 100px;

        @include media-breakpoint-down(lg) {
            margin-left : 30px;
        }
    }
}

nav {
    background-color : #fff;
}

.navbar-light .navbar-nav {
    .nav-link {
        transition : color .3s;

        &:hover,
        &.active {
            color : $primary;
        }
    }

    a {
        font-size : 16px;
    }
}

main {
    ul {
        margin-bottom : 0;
        list-style    : none;
        padding-left  : 20px;

        li {
            position : relative;

            &:before {
                background-image    : url("../img/arrow-right.svg");
                content             : "";
                position            : absolute;
                width               : 8px;
                height              : 10px;
                background-repeat   : no-repeat;
                background-size     : 100%;
                background-position : center;
                left                : -15px;
                top                 : 10px;
            }

            ul {
                padding-left : 15px;

                li {
                    &:before {
                        background-image : url("../img/arrow-single-right.svg");
                        width            : 6px;
                    }
                }
            }
        }
    }

    a {
        text-decoration : none;
        position        : relative;

        &:after {
            content          : "";
            position         : absolute;
            left             : 0;
            bottom           : 0;
            height           : 1px;
            width            : 0;
            background-color : $primary;
            transition       : width .3s;
        }

        &:hover {
            color : $primary;

            &:after {
                width : 100%;
            }
        }
    }
}

.form-group {
    margin-bottom : 15px;
}

.card {
    &.active {
        .card-header {
            background-color : $primary;
            color            : #fff;
        }
    }
}

.payment-chooser {
    position         : relative;
    padding-top      : 100%;
    background-color : #fff;

    input {
        position   : absolute;
        left       : 50%;
        transform  : translateY(-50%);
        appearance : none;
        width      : .5px;
        height     : .5px;
    }
}

.payment-chooser-inner {
    position        : absolute;
    top             : 0;
    right           : 0;
    bottom          : 0;
    left            : 0;
    display         : flex;
    justify-content : center;
    align-items     : center;
    border          : 3px solid transparent;
    transition      : border-color .3s;
    cursor          : pointer;

    .disabled & {
        cursor           : not-allowed;
        background-color : #cfcdcd;
    }

    input:checked + & {
        border-color : $primary;
    }
}

.navbar-nav {
    font-weight : 500;
}

.navbar-lang {
    a {
        text-decoration : none;
        margin-left     : 50px;
    }
}

.nav-item {
    &.hide-desktop {
        display : none;

        @include media-breakpoint-down(md) {
            display : initial;
        }
    }

    &:last-of-type {
        a {
            padding-right : 0 !important;
        }
    }
}

.navbar {
    box-shadow : rgb(0 0 0 / 7%) 0 3px 15px 0;
}

.box-wrapper {
    padding          : 30px 45px;
    background-color : #F8F8F8;
    border-radius    : 30px;
}

.bill-row {
    display        : flex;
    padding-bottom : 10px;
    padding-top    : 10px;
    border-bottom  : 1px solid #B2B2B2;

    @include media-breakpoint-down(md) {
        display : block;
    }
}

.bill-col {
    display     : flex;
    align-items : center;

    @include media-breakpoint-down(md) {
        flex-direction : column;
        align-items    : flex-start;
    }

    &:nth-child(1) {
        flex : 0 0 20%;
    }

    &:nth-child(2) {
        flex : 0 0 20%;
    }

    &:nth-child(3) {
        flex : 0 0 60%;

        @include media-breakpoint-down(md) {
            flex-direction : row;
        }
    }

    .bill-headlines & {
        font-weight : 600;
    }
}

.bill-col-label {
    display : none;

    @include media-breakpoint-down(md) {
        display : block;
    }
}

.bill-headlines {
    @include media-breakpoint-down(md) {
        display : none;
    }
}

.package-wrapper {
    border         : 2px solid #000;
    border-radius  : 30px;
    padding        : 55px 30px 0;
    position       : relative;
    font-size      : 20px;
    height         : 100%;
    display        : flex;
    flex-direction : column;

    &.additional-package {
        border-color : #696969;
        padding-top  : 45px;
    }

    .current-package & {
        border-color : $secondary;
    }

    strong {
        font-weight : 600;
    }

    ul {
        margin-top    : 15px;
        margin-bottom : 30px;
        flex-grow     : 1;
    }


}

.package-action {
    margin-left                : -30px;
    margin-right               : -30px;
    background-color           : #000;
    font-weight                : 500;
    font-size                  : 20px;
    text-transform             : uppercase;
    text-align                 : center;
    border-bottom-left-radius  : 25px;
    border-bottom-right-radius : 25px;
    padding                    : 7px 0;

    .additional-package & {
        background-color : #696969;

        .current-package & {
            pointer-events : initial;
        }
    }

    .current-package & {
        background-color : $secondary;
        pointer-events   : none;
    }

    a {
        color : #fff;

        &:after {
            content : none;
        }

        &:hover {
            color : #fff;
        }
    }
}

.package-name {
    font-weight    : 600;
    text-transform : uppercase;
    text-align     : center;
    font-size      : 26px;
}

.package-icon {
    position         : absolute;
    left             : 50%;
    transform        : translateX(-50%);
    top              : -55px;
    font-size        : 70px;
    background-color : #fff;
    padding-left     : 10px;
    padding-right    : 10px;

    .additional-package & {
        color     : #696969;
        font-size : 55px;
        top       : -40px;
    }

    .current-package & {
        color : $secondary;
    }
}

.home-package {
    display : flex;

    @include media-breakpoint-down(lg) {
        justify-content : center;
    }
}

.font-20 {
    font-size : 20px;
}

.font-40 {
    font-size : 40px;
}

.font-weight-500 {
    font-weight : 500;
}

.enterprise-link {
    font-size   : 20px;
    font-weight : 600;
}
