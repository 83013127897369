.selectpicker,
.file-input-hidden {
    position : absolute;
    width    : 0.5px;
    border   : 0;
    opacity  : 0;
    z-index  : 0;
    display  : block;
    left     : 50%;
    bottom   : 0;
}

.custom-select-picker {
    position : relative;
    width    : 100%;

    &.open {
        z-index : 100;

        > i {
            transform : rotate(180deg);
        }
    }

    > i {
        position   : absolute;
        top        : 10px;
        right      : 15px;
        color      : $primary;
        transition : transform .3s;
        z-index    : 20;
    }
}

.custom-select-selected {
    border           : 1px solid $input-border-color;
    padding          : 6px 30px 6px $form-floating-padding-x;
    cursor           : pointer;
    z-index          : 10;
    position         : relative;
    background-color : $input-bg;

    .custom-select-picker.open & {
        border-bottom-left-radius  : 0;
        border-bottom-right-radius : 0;
    }
}

.custom-select-options {
    overflow-x                 : hidden;
    overflow-y                 : scroll;
    max-height                 : 0;
    transition                 : max-height .3s, opacity .3s;
    border-left                : 1px solid $input-border-color;
    border-right               : 1px solid $input-border-color;
    border-bottom-left-radius  : $input-border-radius;
    border-bottom-right-radius : $input-border-radius;
    opacity                    : 0;
    position                   : absolute;
    left                       : 0;
    right                      : 0;
    top                        : 100%;
    background                 : $body-bg;

    .custom-select-picker.open & {
        border-bottom : 1px solid $input-border-color;
        opacity       : 1;
    }
}

.custom-select-option {
    cursor          : pointer;
    padding         : $input-padding-y 25px $input-padding-y $input-padding-x;
    transition      : background-color .3s, color .3s;
    display         : flex;
    align-items     : center;
    justify-content : space-between;

    &:hover {
        background-color : $primary;
        color            : $white;

        i {
            color : $white;
        }
    }

    &.out {
        display : none;
    }

    &:not(.selected) {
        i {
            display : none;
        }
    }

    i {
        font-size  : 20px;
        color      : $primary;

        transition : color .3s;
    }
}
